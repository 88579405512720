<script setup lang="ts">
import { twMerge } from 'tailwind-merge'

const props = defineProps({
	item: {
		type: Object,
		default: null,
	},
	classDescription: {
		type: String,
		default: '',
	},
	classValue: {
		type: String,
		default: '',
	},
})

const getProgress = computed(() => {
	const percentage = (props.item.used / props.item.value) * 100

	return {
		color: percentage < 50 ? 'green' : percentage < 80 ? 'yellow' : 'red',
		value: percentage,
	}
})

const { locale } = useI18n()
</script>

<template>
	<div class="flex items-center justify-between">
		<div :class="twMerge('text-xs pr-3 flex-1', props.classDescription)">
			{{ locale === 'en' ? props.item.name_en : props.item.name }}
		</div>

		<div :class="twMerge('text-sm font-medium flex-shrink-0', props.classValue)">
			<template v-if="props.item.datatype === 'bool'">
				<UIcon
					v-if="props.item.value"
					name="mdi:check-decagram"
					size="18"
				/>
				<UIcon
					v-else
					name="ic:outline-remove"
					size="18"
				/>
			</template>

			<template v-else-if="props.item.datatype === 'progress'">
				<span class="flex items-center text-nowrap gap-3">
					<span> {{ props.item.used }} de {{ props.item.value }} </span>
					<UProgress
						:value="getProgress.value"
						:color="getProgress.color"
						class="w-12"
					/>
				</span>
			</template>

			<template v-else>
				<template v-if="props.item.datatype === 'string'">
					{{ locale === 'en' ? props.item.value_en : props.item.value }}
				</template>
				<template v-else>
					{{ props.item.value }}
				</template>
			</template>
		</div>
	</div>
</template>

<style scoped></style>
